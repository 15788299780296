$breakpoints: (
    xs: 0px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1280px
);

:root {
    --main-color: #17384b;
    --accent-color: #c7a948;
    --white: #fcfcfc;
    --black: #080d0f;
    --background-color: #f5f4f1;
}

// fichier pour les variables
